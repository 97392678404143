import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Referral from './components/Referral'; 
import Task from './components/Task'; 
import Leaderboard from './components/Leaderboard'; 
import Preload from './components/Preload'; 
import Footer from './components/Footer'; 
import ComingSoon from './components/ComingSoon'; 
import NotFound from './components/NotFound'; 
import CoinCollectPage from './components/CoinCollectPage'; // Import CoinCollectPage component
import { ToastContainer } from 'react-toastify';  // Import ToastContainer for notifications
import 'react-toastify/dist/ReactToastify.css';  // Import toast styles
import GiftBox from './components/GiftBox'; 

function App() {
  const [isPreloading, setIsPreloading] = useState(true);

  // Function to handle when preloading is finished
  const handlePreloadFinish = () => {
    setIsPreloading(false);
  };

  return (
    <Router> {/* Wrapping the entire app with Router */}
      <div className="App">
        {/* Global ToastContainer to display notifications */}
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={1} // Limit to one toast at a time
        />

        {/* Preload screen will be shown until preloading is finished */}
        {isPreloading ? (
          <Preload onPreloadFinish={handlePreloadFinish} />
        ) : (
          <>
            {/* Content and Routes */}
            <div className="flex flex-col items-center min-h-screen">
              <Routes> {/* Define Routes for navigation */}
                <Route path="/home" element={<Home />} />
                <Route path="/referral" element={<Referral />} /> 
                <Route path="/task" element={<Task />} /> 
                <Route path="/leaderboard" element={<Leaderboard />} /> 
                <Route path="/collect" element={<CoinCollectPage />} />

                {/* Additional routes */}
                <Route path="/comingsoon" element={<ComingSoon />} /> 
                <Route path="/nft" element={<ComingSoon />} /> 
                <Route path="/game" element={<ComingSoon />} /> 
                <Route path="/stake" element={<ComingSoon />} /> 
                <Route path="/CoinCollectPage" element={<CoinCollectPage />} />
                <Route path="/GiftBox" element={<GiftBox />} />

                {/* Default route to redirect to Home */}
                <Route path="/" element={<Navigate to="/home" />} />

                {/* 404 Not Found route */}
                <Route path="*" element={<NotFound />} /> 
              </Routes>

              {/* Footer Component */}
              <Footer />
            </div>
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
