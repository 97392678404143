import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

// Success toast with custom styles
export const showSuccessToast = (message) => {
  toast.success(
    <div className="flex items-center">
      <span>{message}</span>
    </div>,
    {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};

// Error toast with custom styles
export const showErrorToast = (message) => {
  toast.error(
    <div className="flex items-center">
      <span>{message}</span>
    </div>,
    {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};
