// src/components/NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-center">
      <h1 className="text-4xl font-bold text-red-500">404</h1>
      <p className="text-lg text-gray-400">Page Not Found</p>
      <a href="/home" className="mt-4 text-blue-500 underline">Go back to Home</a>
    </div>
  );
};

export default NotFound;
