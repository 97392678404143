import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';
import './Preload.css';

const Preload = ({ onPreloadFinish }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate the loading delay for 3 seconds
    const timer = setTimeout(() => {
      setLoading(false);
      onPreloadFinish();
    }, 3000);

    return () => clearTimeout(timer); // Cleanup the timer when the component is unmounted
  }, [onPreloadFinish]);

  return (
    <div className="preload-container">
      <div className="loading-section">
        {/* <img src="../assets/Preload.WEBP" alt="Preload Background" className="preload-bg" /> */}
        {/* <div className="loading-text">Loading</div> */}
        <div className="loading-spinner"></div>
      </div>
      <div className="text-section">
        {/* <h2>Save the date for</h2> */}
        {/* <h1>Mint and Stake</h1>
        <h3>Trex NFT Mint Start On September 26th!</h3>
        <p>Stay tuned</p>
        <p>More info in official channels</p> */}
        <div className="social-icons">
          <a href="https://t.me/realtrexcommunity" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faTelegram} className="social-icon" />
          </a>
          <a href="https://youtube.com/@trontrex" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faYoutube} className="social-icon" />
          </a>
          <a href="https://x.com/trex_mining" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faTwitter} className="social-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Preload;
