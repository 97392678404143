import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './GiftBox.css'; // Custom styling
import { showSuccessToast } from './Notification'; // Import the notification function

const GiftBox = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false); // Prevent multiple clicks

  const handleOpen = () => {
    if (!isDisabled) {
      setIsOpened(true);
      setIsDisabled(true); // Disable further clicks during the animation

      // Trigger the success toast notification
      showSuccessToast("You've opened the gift box! 🎉");

      setTimeout(() => {
        setIsOpened(false);
        setIsDisabled(false); // Enable clicking again after reset
      }, 2000); // Reset after 2 seconds
    }
  };

  return (
    <div className="gift-container">
      <h1>Open Your Gift!</h1>

      {/* Gift box animation */}
      <motion.div
        className="gift-box"
        onClick={handleOpen}
        whileHover={{ scale: 1.1 }}
        initial={{ scale: 1 }}
        animate={
          isOpened
            ? { scale: 1.2, rotate: 20 } // Rotate slightly when clicked
            : { scale: 1, rotate: 0 }
        }
        transition={{ duration: 0.5 }}
      >
        <img
          src={isOpened ? '/openbox.png' : '/box.png'}
          alt="Gift Box"
          className="gift-image"
        />
      </motion.div>

      {/* Coins animation (only show when gift is opened) */}
      {isOpened && (
        <motion.div
          className="coins"
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: -100, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <img
            src="/trexcoin.png"
            alt="Coins"
            className="coins-image"
          />
        </motion.div>
      )}
    </div>
  );
};

export default GiftBox;
