// Import dependencies
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './CoinCollect.css';  // Custom styling for coin

const CoinCollectPage = () => {
  const [isCollected, setIsCollected] = useState(false);

  const handleCollect = () => {
    setIsCollected(true);
    // Reset the coin after a delay for a new collection
    setTimeout(() => setIsCollected(false), 1000);
  };

  return (
    <div className="page-container">
      <h1>Collect the Coin!</h1>

      <motion.div
  className="coin"
  initial={{ y: 0, opacity: 1, scale: 1 }}
  whileHover={{ scale: 1.2 }}  // Add scaling effect on hover
  animate={
    isCollected
      ? { y: -150, opacity: 0, scale: 1.5, rotate: 360 }
      : { y: 0, opacity: 1, scale: 1 }
  }
  transition={{ duration: 0.8 }}
>
  💰
</motion.div>


      <motion.div
        className="collection-spot"
        initial={{ scale: 1 }}
        animate={{ scale: isCollected ? 1.2 : 1 }}
        transition={{ duration: 0.3 }}
      >
        🏦 {/* Replace with a wallet icon/image */}
      </motion.div>

      <button onClick={handleCollect} className="collect-button">
        Collect Coin
      </button>
    </div>
  );
};

export default CoinCollectPage;
