import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCoins } from '@fortawesome/free-solid-svg-icons';
import './Leaderboard.css'; // Optional custom CSS file if needed

const Leaderboard = () => {
  const leaderboardData = [
    { id: 1, username: 'User1', trex: 120000 },
    { id: 2, username: 'User2', trex: 110000 },
    { id: 3, username: 'User3', trex: 105000 },
    { id: 4, username: 'User4', trex: 95000 },
    { id: 5, username: 'User5', trex: 90000 },
    { id: 6, username: 'User6', trex: 85000 },
    { id: 7, username: 'User7', trex: 80000 },
    { id: 8, username: 'User8', trex: 75000 },
    { id: 9, username: 'User9', trex: 70000 },
    { id: 10, username: 'User10', trex: 65000 },
  ];

  return (
    <div className="min-h-screen bg-black px-4 py-8 overflow-auto flex flex-col items-center">
      {/* Back Button and Header */}
      <div className="header flex justify-between items-center mb-4 w-full max-w-md">
        <a href="/" className="text-red-500 flex items-center font-bold text-lg">
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
        </a>
        <h1 className="text-2xl font-bold text-red-500 uppercase underline">
          TREX Leaderboard
        </h1>
      </div>

      {/* Leaderboard Container */}
      <div className="w-full max-w-md bg-gray-900 p-4 rounded-lg shadow-lg">
        <h2 className="text-lg font-semibold text-center text-white mb-4">
          Top TREX Holders
        </h2>
        <ul className="space-y-3 overflow-y-auto max-h-[500px] bg-gray-800 p-4 rounded-lg">
          {leaderboardData.map((user) => (
            <li
              key={user.id}
              className="flex justify-between items-center bg-gray-700 p-4 rounded-md shadow-md"
            >
              <span className="text-yellow-400 font-bold text-lg">{user.id}</span>
              <span className="text-white flex-1 text-left ml-8">{user.username}</span>
              <span className="text-red-500 font-bold flex items-center">
                <FontAwesomeIcon icon={faCoins} className="mr-2 text-red-500" />
                {user.trex.toLocaleString()} TREX
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Leaderboard;
