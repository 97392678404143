import React, { useEffect, useState } from 'react';
import './TaskPreload.css'; // Import the custom CSS

const TaskPreload = ({ onPreloadFinish }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate the loading delay for 6 seconds
    const timer = setTimeout(() => {
      setLoading(false);
      onPreloadFinish();
    }, 10); // Adjust time if needed

    return () => clearTimeout(timer); // Cleanup the timer
  }, [onPreloadFinish]);

  return (
    <div className="preload-container1">
      <div className="loading-section1">
        <div className="loading-spinner1"></div>
      </div>
    </div>
  );
};

export default TaskPreload;
