import React from 'react';

const ComingSoon = () => {
  return (
    <div className="h-screen w-full flex items-center justify-center bg-black">
      <div className="text-center">
        <h1 className="text-4xl md:text-5xl font-bold text-white animate-pulse blur-sm">
          Coming Soon
        </h1>
        <p className="text-xl text-gray-400 mt-4 animate-fade-in">
          We are working hard to launch something amazing. Stay tuned!
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
