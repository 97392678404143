import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faCopy, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import './Referral.css';
import TaskPreload from './TaskPreload'; // Import the Preload component
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

const Referral = () => {
  const [referralLink, setReferralLink] = useState('');
  const [referralStats, setReferralStats] = useState({
    totalReferrals: 0,
    trexEarned: 0
  });
  const [referredUsers, setReferredUsers] = useState([]);
  const [userId, setUserId] = useState(null); // Telegram user ID
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state to handle loading

  useEffect(() => {
    const getTelegramUser = () => {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe) {
        const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
        if (telegramUser) {
          const telegramUserId = telegramUser.id.toString(); // Convert ID to string
          setUserId(telegramUserId);

          // Update the referral link to match the format
          setReferralLink(`https://t.me/TrexTronBot?start=trex${telegramUserId}`);
          fetchReferralStats(telegramUserId);
        } else {
          toast.error('User data is not available from Telegram Web App.');
        }
      } else {
        toast.error('Telegram WebApp is not available.');
      }
    };

    const fetchReferralStats = async (userId) => {
      try {
        const response = await axios.get(`https://api.trexcm.live/referrals/${userId}`);
        setReferralStats(response.data.stats);

        // Sort referred users by the `trexEarned` field in descending order
        const sortedUsers = response.data.referredUsers.sort((a, b) => b.trexEarned - a.trexEarned);
        setReferredUsers(sortedUsers);
      } catch (error) {
        toast.error('Error fetching referral stats: ' + error.message);
      } finally {
        setIsLoading(false); // Set isLoading to false after data is fetched
      }
    };

    getTelegramUser();
  }, []);

  const copyLink = () => {
    navigator.clipboard.writeText(referralLink);
    toast.success('Referral link copied! 🔗');
  };

  // This function will open the share dialog using Telegram's deep link
  const shareReferralLink = () => {
    if (window.Telegram) {
      const text = encodeURIComponent("Join TREX Mining using my referral link!");
      const url = referralLink;

      // Create the deep link
      const shareUrl = `https://t.me/share/url?url=${url}&text=${text}`;

      // Open the share URL
      window.open(shareUrl, "_blank");
    } else {
      toast.error('Telegram Web App is not available.');
    }
  };

  const handleImageError = (e) => {
    e.target.src = 'https://legendary-eclair-6f6b59.netlify.app/trextask.png';  // Fallback image
  };

  // Show loading spinner while data is being fetched
  if (isLoading) {
    return <TaskPreload />; // Render TaskPreload component while loading
  }

  return (
    <div className="min-h-screen bg-cover bg-no-repeat px-4 py-8 overflow-auto">
      {/* Header */}
      <div className="header flex justify-between items-center mb-4 ctm">
        <h1 className="text-2xl font-bold text-red-500 uppercase">TREX Referrals</h1>
      </div>

      {/* Referral Dashboard */}
      <div className="dashboard bg-gray-100 p-4 rounded-md shadow-md mb-6">
        <h2 className="text-lg font-semibold text-center">Referral Stats</h2>
        <div className="flex justify-between mt-2 text-gray-700">
          <p><FontAwesomeIcon icon={faUserFriends} className='text-yellow-400 mr-1' /> Total Referrals: <span className="font-bold text-red-500 mr-8">{referralStats.totalReferrals}</span></p>
          <p><FontAwesomeIcon icon={faCoins} className='text-yellow-400 mr-1' /> TREX Earned: <span className="font-bold text-red-500">{referralStats.trexEarned} TREX</span></p>
        </div>
      </div>

      {/* Share Referral Link */}
      <div className="text-center mb-6">
        <h3 className="text-lg font-semibold">Invite Friends</h3>
        <div className="link-container flex items-center justify-center gap-4 mt-2">
          {/* Invite Friends Button */}
          <button
            onClick={shareReferralLink}
            className="invite-btn text-white py-2 px-4 rounded-lg transition"
          >
            <FontAwesomeIcon icon={faUserFriends} style={{ marginRight: 5 }} /> Invite Friends
          </button>

          {/* Copy Link Button */}
          <button
            onClick={copyLink}
            className="copy-btn text-white py-2 px-4 rounded-lg transition"
          >
            <FontAwesomeIcon icon={faCopy} style={{ fontSize: "20px" }} />
          </button>
        </div>
      </div>

      {/* Referred Users List */}
      <div className="text-center">
        <h3 className="text-lg font-semibold mb-4">Your Referred Users</h3>
        <ul className="space-y-3 overflow-y-auto max-h-[350px] bg-black rounded-t-custom">
          {referredUsers.length === 0 ? (
            <p>No referred users yet.</p>
          ) : (
            referredUsers.map((user, index) => (
              <li key={index} className="rounded-lg flex items-center justify-between bg-white p-3 shadow-md">
                <div className="flex userli items-center">
                  <img
                    src={user.profilePhotoUrl || 'https://legendary-eclair-6f6b59.netlify.app/trextask.png'}
                    alt={`Profile ${index + 1}`}
                    className="w-10 h-10 rounded-full bg-gray-500 bg-opacity-30 mr-3"
                    onError={handleImageError}
                  />
                  <span style={{color:"#ff0000", fontWeight: 'bold'}}>{user.fullName || `User ${index + 1}`}</span>
                </div>
                <span className="text-green-600">+{user.trexEarned || 0} TREX</span>
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
};

export default Referral;
