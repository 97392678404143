import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faXmark, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './PopupTask.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

Modal.setAppElement('#root');

const PopupTask = ({ isOpen, onClose, task, userId, onTaskCompleted }) => {
  const [taskStatus, setTaskStatus] = useState('none');
  const [isWaiting, setIsWaiting] = useState(false);

  // Dynamically construct the image source based on task type
  const iconSrc = task ? `/${task.type}.png` : '';

  // Check task status when the modal is opened but do NOT mark it as completed
  useEffect(() => {
    const checkTaskStatusOnLoad = async () => {
      if (!task || !userId) return; // Ensure both task and userId exist

      try {
        const response = await fetch('https://api.trexcm.live/api/check_task', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userId, // Pass the Telegram user ID
            task_id: task.id, // Ensure the task ID is sent correctly
          }),
        });

        const result = await response.json();

        // Only set the task status based on what the backend returns, without marking it as completed
        if (response.ok && result.status === 'completed') {
          setTaskStatus('completed'); // Display the task as completed if it already is
        } else if (response.ok && result.status === 'pending') {
          setTaskStatus('pending'); // Set task status to pending if it exists
        } else {
          setTaskStatus('none'); // Default state when neither completed nor pending
        }
      } catch (error) {
        console.error('Error checking task status on load:', error);
      }
    };

    if (isOpen) {
      checkTaskStatusOnLoad(); // Run the check if the modal is open
    }
  }, [task, userId, isOpen]);

  // Function to handle button click and open the task link in a new tab
  const handleActionButtonClick = async () => {
    window.open(task.link, '_blank'); // Opens the task link in a new tab

    const clickTime = new Date().toISOString(); // Get the current time

    try {
      const response = await fetch('https://api.trexcm.live/api/user_task', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId, // Pass the Telegram user ID
          task_id: task.id, // Extract task details
          task_text: task.text,
          task_reward: task.reward,
          task_type: task.type,
          task_link: task.link,
          task_interval: task.interval || 0, // Pass interval or 0 if null
          task_status: 'pending', // Set task status to pending
          click_time: clickTime, // Set the current time
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to record task action');
      }

      setTaskStatus('pending'); // Immediately set task status to pending after adding to the database
      setIsWaiting(false); // Ensure the waiting state is false when only clicking the action button
    } catch (error) {
      console.error('Error recording task:', error);
    }
  };

  // Function to handle "Check" button click to check task status and update it to "completed"
  const handleCheckButtonClick = async () => {
    try {
      setIsWaiting(true); // Indicate waiting status during the check

      const response = await fetch('https://api.trexcm.live/api/check_task', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId, // Pass the Telegram user ID
          task_id: task.id, // Ensure the task ID is sent correctly
        }),
      });

      const result = await response.json();

      if (response.ok) {
        const clickTime = new Date(result.click_time); // Parse the click_time from the response
        const taskInterval = result.task_interval * 1000; // Convert task_interval to milliseconds
        const currentTime = new Date(); // Get the current time

        // Calculate the difference between current time and click time
        const timeElapsed = currentTime - clickTime;

        // If the time elapsed is greater than or equal to the task interval, mark as completed
        if (timeElapsed >= taskInterval) {
          setTaskStatus('completed'); // Set task status to completed
          onTaskCompleted(task.id); // Notify the parent component to update the task status in the list
          toast.success('Task completed! Rewards have been credited.');
        } else {
          // If not enough time has passed, show this message
          toast.warn('Make sure you have completed the task correctly before checking again.');
        }
      } else {
        toast.error('Failed to check task status. Please try again later.');
      }
    } catch (error) {
      console.error('Error checking task status:', error);
      toast.error('An error occurred while checking the task status. Please try again.');
    } finally {
      setIsWaiting(false); // Reset waiting status regardless of the result
    }
  };

  if (!task) {
    return null; // Return null if no task is provided
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-content border-t-4 border-gray-700 rounded-t-custom"
      overlayClassName="modal-overlay"
    >
      {/* Close button */}
      <button onClick={onClose} className="close-btn">
        <FontAwesomeIcon icon={faXmark} />
      </button>

      {/* Task Icon and Title */}
      <div className="modal-header">
        <img src={iconSrc} alt={task.type} className="modal-icon" style={{ width: '100px', height: '100px' }} />
        <h2>{task.text}</h2>
      </div>

      {/* Task Description */}
      <p className="modal-description">{task.description}</p>

      {/* Reward Section */}
      <div className="reward-section">
        <FontAwesomeIcon icon={faCoins} className="coins-icon" />
        <span>{task.reward}</span>
      </div>

      {/* Action Button to open task link */}
      <button className="action-btn" onClick={handleActionButtonClick}>
        {task.button}
      </button>

      {/* Check Button only if task is pending */}
      {taskStatus === 'pending' && (
        <button className="check-btn" onClick={handleCheckButtonClick} disabled={isWaiting}>
          {isWaiting ? 'Checking...' : 'Check'}
        </button>
      )}

      {/* Display task completion status */}
      {taskStatus === 'completed' && (
        <div className="task-completed">
          <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
          <span>Task Completed</span>
        </div>
      )}
    </Modal>
  );
};

export default PopupTask;
