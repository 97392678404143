import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import PopupTask from './PopupTask';
import Preload from './TaskPreload'; // Import the Preload component
import './TaskList.css';

const TaskList = () => {
  const [tasks, setTasks] = useState([]); // Initialize as an empty array
  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setUserId] = useState(null); // Store Telegram user ID
  const [loading, setLoading] = useState(true); // Track loading state
  const [preloadFinished, setPreloadFinished] = useState(false); // Track if preload is finished

  // Fetch tasks and task completion statuses from the backend
  useEffect(() => {
    const getTelegramUserAndFetchTasks = () => {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe) {
        const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;

        if (telegramUser) {
          // Get the Telegram user ID from the Web App context
          const telegramUserId = telegramUser.id.toString();
          setUserId(telegramUserId);
          fetchTasksAndStatus(telegramUserId);
        } else {
          console.error('User data is not available from Telegram Web App.');
        }
      } else {
        console.error('Telegram WebApp is not available.');
      }
    };

    const fetchTasksAndStatus = async (telegramUserId) => {
      try {
        // Fetch the tasks
        const taskResponse = await fetch('https://api.trexcm.live/api/tasks');
        const taskData = await taskResponse.json();

        if (Array.isArray(taskData)) {
          // Set the fetched tasks
          const tasksWithStatus = await Promise.all(taskData.map(async (task) => {
            // Fetch task completion status for each task
            const statusResponse = await fetch('https://api.trexcm.live/api/check_task', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                user_id: telegramUserId,
                task_id: task.id
              }),
            });

            const statusResult = await statusResponse.json();

            // Return task with its status without marking it completed prematurely
            return {
              ...task,
              taskStatus: statusResult.status,
              isCompleted: statusResult.status === 'completed', // Retain green check ✅ when completed
            };
          }));

          setTasks(tasksWithStatus); // Set tasks with completion statuses
        } else {
          console.error('Error: Expected an array but got', taskData);
        }
      } catch (error) {
        console.error('Error fetching tasks or statuses:', error);
      } finally {
        setLoading(false); // End loading
      }
    };

    getTelegramUserAndFetchTasks();
  }, []); // Only run once when the component mounts

  // Open modal when a task is clicked
  const openModal = (task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTask(null);
  };

  // Handle the finish of the preload animation
  const handlePreloadFinish = () => {
    setPreloadFinished(true); // Mark preload as finished
  };

  // This function will handle task completion in real-time
  const markTaskAsCompleted = (taskId) => {
    setTasks((prevTasks) => 
      prevTasks.map((task) => 
        task.id === taskId ? { ...task, isCompleted: true } : task
      )
    );
  };

  if (!preloadFinished || loading) {
    return <Preload onPreloadFinish={handlePreloadFinish} />;
  }

  return (
    <div className="task-list-wrapper">
      {/* Header section */}
      <div className="header-section">
        <img src="/trextask.png" alt="Coin" className="coin-icon" />
      </div>

      {/* Scrollable task list */}
      <div className="scrollable-task-list border-t-4 border-gray-700 rounded-t-custom">
        {tasks.length === 0 ? (
          <p>No tasks available.</p>
        ) : (
          <>
            <TaskSection title="Trex Tasks">
              {tasks.filter(task => task.section === "Trex Tasks").map((task, index) => (
                <TaskItem
                  key={index}
                  task={task}
                  onClick={() => openModal(task)}
                />
              ))}
            </TaskSection>

            <TaskSection title="Tasks list">
              {tasks.filter(task => task.section === "Tasks list").map((task, index) => (
                <TaskItem
                  key={index}
                  task={task}
                  onClick={() => openModal(task)}
                />
              ))}
            </TaskSection>
          </>
        )}
      </div>

      {/* Popup Task Modal */}
      {selectedTask && (
        <PopupTask
          isOpen={isModalOpen}
          onClose={closeModal}
          task={selectedTask}
          userId={userId}
          onTaskCompleted={markTaskAsCompleted} // Pass the function to update task status
        />
      )}
    </div>
  );
};

const TaskSection = ({ title, children }) => (
  <div className="task-section">
    <h2 className="task-section-title">{title}</h2>
    {children}
  </div>
);

const TaskItem = ({ task, onClick }) => {
  const [countdown, setCountdown] = useState("");

  // Countdown effect
  useEffect(() => {
    const updateCountdown = () => {
      if (!task.exp_at) return '';

      const expirationTime = new Date(task.exp_at).getTime();
      const now = new Date().getTime();
      const remainingTime = expirationTime - now;

      if (remainingTime <= 0) {
        setCountdown('Expired');
        return;
      }

      const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

      setCountdown(`${days}d:${hours}h:${minutes}m:${seconds}s`);
    };

    const intervalId = setInterval(updateCountdown, 1000); // Update countdown every second
    updateCountdown(); // Initialize countdown immediately

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [task.exp_at]);

  return (
    <div className="task-item" onClick={onClick}>
      {/* Dynamically construct the image path based on task type */}
      <img
        src={`/${task.type}.png`} // Dynamically use the task type to load the correct image
        alt={task.text}
        className="task-icon"
      />
      <div className="task-details">
        <p>{task.text}</p>
        <div className="task-meta">
          {countdown && <span className="exp-date">{countdown}</span>}
          {task.reward && (
            <span className="reward" style={{ marginRight: '10px' }}>
              +{task.reward} TREX
            </span>
          )}
        </div>
      </div>
      {/* Display the checkmark if the task is completed */}
      {task.isCompleted && <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />}
    </div>
  );
};

export default TaskList;
