import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faHandHoldingUsd, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import TaskPreload from './TaskPreload';  // Import the Preload component
import { toast } from 'react-toastify'; // Import Toastify for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

const Home = () => {
  const [balance, setBalance] = useState(0);
  const [trexPerClaim, setTrexPerClaim] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [claimIntervalMs, setClaimIntervalMs] = useState(0);
  const [canClaim, setCanClaim] = useState(true);
  const [userId, setUserId] = useState(null); // Telegram user ID
  const [userExists, setUserExists] = useState(false); // To track if the user exists in the database
  const [referralStats, setReferralStats] = useState({ totalReferrals: 0, trexEarned: 0 });
  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Fetch Telegram user data when the component mounts
  useEffect(() => {
    const getTelegramUser = () => {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe) {
        const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
        if (telegramUser) {
          const telegramUserId = telegramUser.id.toString(); // Convert ID to string
          setUserId(telegramUserId);
        } else {
          alert('Error: Unable to access Telegram user data.');
        }
      } else {
        alert('Error: Telegram WebApp is not available. Please access through Telegram.');
      }
    };

    getTelegramUser();
  }, []);

  // Fetch user data from the backend when the Telegram user ID is available
  useEffect(() => {
    const checkUserExists = async () => {
      if (!userId) return;

      setIsLoading(true); // Start loading
      try {
        const response = await axios.get(`https://api.trexcm.live/user/${userId}`);
        if (response.data && response.data.balance !== undefined) {
          setUserExists(true);
          setBalance(response.data.balance);
          setTrexPerClaim(response.data.trexPerClaim);
          setClaimIntervalMs(response.data.claimIntervalMs);
          setTimeRemaining(response.data.timeRemaining);
          setCanClaim(response.data.timeRemaining === 0);
        } else {
          setUserExists(false);
        }
      } catch (error) {
        console.error('Error checking user in database:', error);
        setUserExists(false);
      } finally {
        setIsLoading(false); // End loading
      }
    };

    checkUserExists();
  }, [userId]);

  // Fetch referral stats when user exists
  useEffect(() => {
    const fetchReferralStats = async (userId) => {
      try {
        const response = await axios.get(`https://api.trexcm.live/referrals/${userId}`);
        setReferralStats(response.data.stats);
      } catch (error) {
        console.error('Error fetching referral stats:', error);
      }
    };

    if (userExists) {
      fetchReferralStats(userId);
    }
  }, [userExists, userId]);

  // Claim reward and update balance immediately without querying it again
  const claimReward = async () => {
    if (!canClaim || !userId) return;
  
    try {
      // Make the claim request to the backend
      const response = await axios.post('https://api.trexcm.live/claim', { userId });
  
      // Check if the response contains a success flag
      if (response.data && response.data.success) {
        // Immediately update the balance in the frontend
        setBalance((prevBalance) => prevBalance + trexPerClaim);
  
        // Show success notification
        toast.success(`You claimed ${trexPerClaim} TREX! 🎉`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
  
        // Reset the timer and disable the claim button until the timer is up
        setTimeRemaining(claimIntervalMs); // Reset timer to the claim interval
        setCanClaim(false); // Disable claim button until the timer resets
      } else {
        // If response does not contain success: true, show an error notification
        toast.error('Claim was unsuccessful', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      // Show error notification for any network or server issues
      toast.error('An error occurred while claiming the reward. Please check your connection and try again.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }    
  };
  
  // Format the time for display (minutes and seconds)
  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  // Countdown timer effect
  useEffect(() => {
    if (timeRemaining > 0) {
      const interval = setInterval(() => {
        setTimeRemaining((prev) => {
          if (prev <= 1000) {
            setCanClaim(true); // Allow claim when timer reaches zero
            clearInterval(interval); // Stop the interval
            return 0;
          }
          return prev - 1000; // Subtract 1 second (1000 milliseconds)
        });
      }, 1000);

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [timeRemaining]);

  // If loading, show Preload component
  if (isLoading) {
    return <TaskPreload />;
  }

  // If user doesn't exist, show error message
  if (!userExists) {
    return (
      <div className="min-h-screen p-4 bg-cover bg-no-repeat">
        <header className="text-center mb-6">
          <h1 className="text-4xl font-bold text-red-500">TREX Mining</h1>
          <p className="text-lg text-gray-400">We couldn't find your user information. Please try again later.</p>
        </header>
      </div>
    );
  }

  // Main content when user exists and data is loaded
  return (
    <div className="min-h-screen p-4 bg-cover bg-no-repeat">
      <header className="text-center mb-6">
        <h1 className="text-4xl font-bold text-red-500">TREX Mining</h1>
        <p className="text-lg text-gray-400">Earn TREX Every Hour - Powered by Tron</p>
      </header>

      <div className="mining-card bg-gray-800 max-w-md w-full p-6 rounded-lg shadow-lg text-center">
        <h2 className="text-yellow-500 text-2xl font-bold mb-2">Total Balance: {balance} TREX</h2>
        <p className="text-gray-300 mb-4">TREX Per Claim: {trexPerClaim}</p>

        {canClaim ? (
          <button
            className="claim-btn text-white py-2 px-4 rounded-lg font-bold transition transform hover:scale-105"
            onClick={claimReward}
          >
            <FontAwesomeIcon icon={faHandHoldingUsd} className="mr-2" /> Claim {trexPerClaim} TREX
          </button>
        ) : (
          <div className="text-lg font-bold text-white mt-4">
            <p>Next claim available in:</p>
            <div className="bg-dark-red text-white py-2 px-4 rounded-lg mt-2">
              {formatTime(timeRemaining)}
            </div>
          </div>
        )}
      </div>

      <div className="mt-6">
        <img src="/trex-icon.png" alt="T-Rex" className="big-trex-icon w-32 h-32 md:w-48 md:h-48" />
      </div>

      <div className="dashboard bg-gray-200 p-4 mt-6 rounded-md shadow-md max-w-md w-full">
        <h2 className="text-lg font-semibold mb-4 text-gray-300">Referral Stats</h2>
        <div className="stats flex flex-col text-gray-400">
          <p className="text-sm mb-2">
            <FontAwesomeIcon icon={faUserFriends} className="text-yellow-500 mr-2" />
            Total Referrals: <span className="font-bold text-red-500">{referralStats.totalReferrals}</span>
          </p>
          <p className="text-sm">
            <FontAwesomeIcon icon={faCoins} className="text-yellow-500 mr-2" />
            TREX Earned: <span className="font-bold text-red-500">{referralStats.trexEarned}</span>
          </p>
        </div>
      </div>

    </div>
  );
};

export default Home;
