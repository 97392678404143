import React from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation from React Router
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChessBoard, faCoins, faCopy, faGamepad, faHandHoldingUsd, faHome, faMoneyBillTransfer, faPaintBrush, faTasks, faUserFriends } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  const location = useLocation(); // Get the current route

  return (
    <footer className="fixed bottom-0 w-full bg-black p-4 border-t-4 border-gray-700 rounded-t-custom">
      <div className="flex flex-wrap justify-center gap-1">
        <div className={`btn-h w-12 h-12 rounded-lg flex flex-col items-center justify-center shadow-lg mr-2
          ${location.pathname === '/Home' ? 'text-[#ff0000]' : 'text-white'} 
          hover:text-[#ff0000] active:text-[#ff0000]`}>
          <Link to="/Home" className="flex flex-col items-center justify-center"> {/* Link to Home Page */}
            <FontAwesomeIcon icon={faHome} className="w-4 h-4 mb-1" />
            <p className="text-[11px]">Home</p>
          </Link>
        </div>
        
        <div className={`btn-h w-12 h-12 rounded-lg flex flex-col items-center justify-center shadow-lg mr-2
          ${location.pathname === '/task' ? 'text-[#ff0000]' : 'text-white'} 
          hover:text-[#ff0000] active:text-[#ff0000]`}>
          <Link to="/task" className="flex flex-col items-center justify-center"> {/* Link to Task Page */}
            <FontAwesomeIcon icon={faTasks} className="w-4 h-4 mb-1" />
            <p className="text-[11px]">Task</p>
          </Link>
        </div>

        <div className={`btn-h w-12 h-12 rounded-lg flex flex-col items-center justify-center shadow-lg mr-2
          ${location.pathname === '/referral' ? 'text-[#ff0000]' : 'text-white'} 
          hover:text-[#ff0000] active:text-[#ff0000]`}>
          <Link to="/referral" className="flex flex-col items-center justify-center"> {/* Link to Referral Page */}
            <FontAwesomeIcon icon={faUserFriends} className="w-4 h-4 mb-1" />
            <p className="text-[11px]">Frens</p>
          </Link>
        </div>

        <div className={`btn-h w-12 h-12 rounded-lg flex flex-col items-center justify-center shadow-lg mr-2
          ${location.pathname === '/comingsoon' ? 'text-[#ff0000]' : 'text-white'} 
          hover:text-[#ff0000] active:text-[#ff0000]`}>
          <Link to="/comingsoon" className="flex flex-col items-center justify-center"> {/* Link to NFT Page */}
            <FontAwesomeIcon icon={faPaintBrush} className="w-4 h-4 mb-1" />
            <p className="text-[11px]">NFT</p>
          </Link>
        </div>

        <div className={`btn-h w-12 h-12 rounded-lg flex flex-col items-center justify-center shadow-lg mr-2
          ${location.pathname === '/comingsoon' ? 'text-[#ff0000]' : 'text-white'} 
          hover:text-[#ff0000] active:text-[#ff0000]`}>
          <Link to="/comingsoon" className="flex flex-col items-center justify-center"> {/* Link to Game Page */}
            <FontAwesomeIcon icon={faGamepad} className="w-4 h-4 mb-1" />
            <p className="text-[11px]">Game</p>
          </Link>
        </div> 

        <div className={`btn-h w-12 h-12 rounded-lg flex flex-col items-center justify-center shadow-lg
          ${location.pathname === '/comingsoon' ? 'text-[#ff0000]' : 'text-white'} 
          hover:text-[#ff0000] active:text-[#ff0000]`}>
          <Link to="/comingsoon" className="flex flex-col items-center justify-center"> {/* Link to Stake Page */}
            <FontAwesomeIcon icon={faMoneyBillTransfer} className="w-4 h-4 mb-1" />
            <p className="text-[11px]">Stake</p>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
